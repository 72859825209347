import React from 'react'
import styled from 'styled-components'
import { TYPE } from '../../Theme'
import { RowFixed } from '../Row'
import checkedIcon from '../../assets/checked_icon.svg';

const StyleCheckbox = styled.input`
  position:relation;
  appearance: none;
  width: 14px;
  height: 14px;
  background-color: ${({ theme,checked }) => (checked ? theme.bg9 : '#fff')};
  border: 1px solid ${({ theme,checked }) => (checked ? theme.bg9 : '#fff')};
  border-radius: 2px;
  cursor: pointer;

  :hover {
    cursor: pointer;
  }

  &:checked::before {
    content: url(${checkedIcon});
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    margin-top:-1px;
    margin-left:-1.5px
    width: 10px;
    height: 10px;
  }
`

const ButtonText = styled(TYPE.main)`
  cursor: pointer;
  :hover {
    opacity: 0.6;
  }
`

const CheckBox = ({ checked, setChecked, text }) => {
  return (
    <RowFixed>
      <StyleCheckbox name="checkbox" type="checkbox" checked={checked} onChange={setChecked} />
      <ButtonText ml={'4px'} onClick={setChecked}>
        {text}
      </ButtonText>
    </RowFixed>
  )
}

export default CheckBox
