import React from 'react'
import styled from 'styled-components'
//import { AutoColumn } from '../Column'
//import { AutoRow } from '../Row'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { transparentize } from 'polished'
///import { TYPE } from '../../Theme'
import { withRouter } from 'react-router-dom'
///import { TrendingUp, List, PieChart, Disc } from 'react-feather'
import Link from '../Link'
////import { useSessionStart } from '../../contexts/Application'
//import { useDarkModeManager } from '../../contexts/LocalStorage'
//import Toggle from '../Toggle'
import Logo1 from '../../assets/ob_d.svg'
import Logo2 from '../../assets/ob_t.svg'

const Wrapper = styled.div`
  
  height: ${({ isMobile }) => (isMobile ? 'initial' : '60px')};
  background-color: ${({ theme }) => transparentize(1, theme.bg7)};
  color: ${({ theme }) => theme.text1};
  position: sticky;
  top: 0px;
  z-index: 9999;
  box-sizing: border-box;
  /* background-color: #1b1c22; */
  /* background: linear-gradient(193.68deg, #1b1c22 0.68%, #000000 100.48%);*/
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  color: ${({ theme,activeText }) => (activeText ? theme.primaryText2 : theme.primaryText3)};
  display: flex;
  :hover {
    opacity: 1;
  }
`
const DesktopWrapper = styled.div`
  width: calc(100% - ${({ open }) => (open ? '200px' : '64px')});
  height: 100%;
  box-sizing: border-box;
`
const DesktopContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 32px;
  box-sizing: border-box;
`

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ gap }) => gap};
`


const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  width:40px;
  height:40px;
`

// const Polling = styled.div`
//   position: fixed;
//   display: flex;
//   left: 0;
//   bottom: 0;
//   padding: 1rem;
//   color: white;
//   opacity: 0.4;
//   transition: opacity 0.25s ease;
//   :hover {
//     opacity: 1;
//   }
// `
// const PollingDot = styled.div`
//   width: 8px;
//   height: 8px;
//   min-height: 8px;
//   min-width: 8px;
//   margin-right: 0.5rem;
//   margin-top: 3px;
//   border-radius: 50%;
//   background-color: ${({ theme }) => theme.green1};
// `

function HeaderNav({ history,openNav }) {
  const below1080 = useMedia('(max-width: 1080px)')

  // const below1180 = useMedia('(max-width: 1180px)')
  // const seconds = useSessionStart()
  // const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper open={openNav}>
          <DesktopContentWrapper>
          <RowWrapper gap="0 60px">
            <Title />
            {!below1080 && (
              <RowWrapper gap="0 50px">
                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>
                    {/* <TrendingUp size={20} style={{ marginRight: '.75rem' }} /> */}
                    Overview
                  </Option>
                </BasicLink>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    {/* <Disc size={20} style={{ marginRight: '.75rem' }} /> */}
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    {/* <PieChart size={20} style={{ marginRight: '.75rem' }} /> */}
                    Pairs
                  </Option>
                </BasicLink>
                <BasicLink to="/accounts">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'accounts' ||
                        history.location.pathname.split('/')[1] === 'account') ??
                      undefined
                    }
                  >
                    {/* <List size={20} style={{ marginRight: '.75rem' }} /> */}
                    Accounts
                  </Option>
                </BasicLink>
              </RowWrapper>
            )}
          </RowWrapper>
          <RowWrapper gap="0 16px">
            {/* <HeaderText>
              <Link href="https://uniswap.org" target="_blank">
                Uniswap.org
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://v1.uniswap.info" target="_blank">
                V1 Analytics
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://uniswap.org/docs/v2" target="_blank">
                Docs
              </Link>
            </HeaderText> */}
            <HeaderText>
              <Link href="https://discord.gg/E2k6BUMPSd" target="_blank">
                <img width={'40px'} height={'40px'}  src={Logo1} alt='' />
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://twitter.com/InitVerse" target="_blank">
              <img width={'40px'} height={'40px'}  src={Logo2} alt='' />
              </Link>
            </HeaderText>
            {/* <Toggle isActive={isDark} toggle={toggleDarkMode} /> */}
          </RowWrapper>
          {/* {!below1180 && (
            <Polling style={{ marginLeft: '.5rem' }}>
              <PollingDot />
              <a href="/" style={{ color: 'white' }}>
                <TYPE.small color={'white'}>
                  Updated {!!seconds ? seconds + 's' : '-'} ago <br />
                </TYPE.small>
              </a>
            </Polling>
          )} */}
          </DesktopContentWrapper>
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          <Title />
        </MobileWrapper>
      )}
    </Wrapper>
  )
}

export default withRouter(HeaderNav)
